import React from "react"
import { Link, navigate } from "gatsby"
import { useTranslation,  } from "react-i18next"
import { changeLanguage } from "i18next";
import { Icon } from "@fluentui/react";
import i18next from "i18next";

declare global {
  interface Window {
      $chatwoot: any;
  }
}

const Header: React.FunctionComponent = () => {
  const { t } = useTranslation("app")

  const setLanguage = (lng:string) => {
    changeLanguage(lng)
  }

  const languages = {
    en: 'English',
    es: 'Español'
}

  function collapseNav() {
    const navbar:any = document.getElementById("navbarMainContent");
    if (navbar.className.includes("show"))
      navbar.className = navbar.className.replace(" show", "");
  }

  return (

    <div id="header-id" className="container-fluid p-0" style={{zIndex:10, backgroundColor:"white", position:"sticky", top:0}}>
      <nav className="navbar main navbar-expand-lg navbar-light">
        <div className="container-fluid maxWidth" style={{minHeight:"60px"}}>          
            <button className="navbar-toggler p-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarMainContent" aria-controls="navbarMainContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <Link to="/" className="navbar-brand mx-auto ">
              <img src="/logo/synergy_long_color.png" className="mx-auto d-block" height="24px" alt="Synergy Inc. logo" />
            </Link>            
            <div className="collapse navbar-collapse navbar-expand-sm" id="navbarMainContent">
              <hr className="vertical my-0 mx-3" />
              <ul className="navbar-nav align-items-center ms-auto">
                <li className="nav-item dropdown">
                  <span className="nav-link no-pointer" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-expanded="false" style={{whiteSpace:"nowrap"}}>
                    {t('platform')}
                    </span>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown2">
                      <li>
                        <Link style={{textTransform: 'none'}} onClick={() => collapseNav()} className="dropdown-item nav-link d-flex align-items-center py-3 px-4" aria-current="page" to="/features">
                          <div>
                            <img className="me-4" style={{width: '40px'}} src='/icons/MO_co_authoring.svg'/>
                          </div>
                          <div>
                            <p className="m-0 p-0">{t('platformFeatures')}</p>
                            <p className="m-0 p-0 header-description">{t('platformFeaturesDescription')}</p>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link style={{textTransform: 'none'}} onClick={() => collapseNav()} className="dropdown-item nav-link d-flex align-items-center py-3 px-4" aria-current="page" to="/platform">
                          <div>
                            <img className="me-4" style={{width: '40px'}} src='/icons/Saas.svg'/>
                          </div>
                          <div>
                            <p className="m-0 p-0">{t('platformArchitecture')}</p>
                            <p className="m-0 p-0 header-description">{t('platformArchitectureDescription')}</p>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link style={{textTransform: 'none'}} onClick={() => collapseNav()} className="dropdown-item nav-link d-flex align-items-center py-3 px-4" aria-current="page" to="/whistleblowing">
                          <div>
                            <img className="me-4" style={{width: '40px'}} src='/img/illustrations/whistle.png'/>
                          </div>
                          <div>
                            <p className="m-0 p-0">{t('whistleblower_title')}</p>
                            <p className="m-0 p-0 header-description">{t('whistleblower_subtitle')}</p>
                          </div>
                        </Link>
                      </li>
                      <hr className="m-0 p-0" style={{color: 'grey'}}/>
                      <li>
                        <Link style={{textTransform: 'none'}} onClick={() => collapseNav()} className="dropdown-item nav-link d-flex align-items-center py-3 px-4" aria-current="page" to="/plans">
                          <div>
                            <img className="me-4" style={{width: '40px'}} src='/icons/Audit trails.svg'/>
                          </div>
                          <div>
                            <p className="m-0 p-0">{t('plans')}</p>
                            <p className="m-0 p-0 header-description">{t('plansDescription')}</p>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <a style={{textTransform: 'none'}} className="dropdown-item nav-link d-flex align-items-center py-3 px-4" aria-current="page" href="https://trust.synergyos.com/" target="_blank">
                          <div>
                            <img className="me-4" style={{width: '40px'}} src='/icons/External_permissions.svg'/>
                          </div>
                          <div>
                            <p className="m-0 p-0">{t('trustReport')}</p>
                            <p className="m-0 p-0 header-description">{t('trustReportDescription')}</p>
                          </div>
                        </a>
                      </li>              
                    </ul>
                </li>
                <li className="nav-item">
                  <Link onClick={() => collapseNav()} className="nav-link" aria-current="page" to="/blog">{t('blog')}</Link>
                </li>
                <li className="nav-item dropdown">
                  <span className="nav-link no-pointer" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-expanded="false" style={{whiteSpace:"nowrap"}}>
                    {t('support')}
                    </span>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown2">
                      <li>
                        <a style={{textTransform: 'none'}} onClick={() => collapseNav()} className="dropdown-item nav-link d-flex align-items-center py-3 px-4" aria-current="page" href="https://docs.synergyos.com">
                          <div>
                            <img className="me-4" style={{width: '40px'}} src='/icons/Docu_website.svg'/>
                          </div>
                          <div>
                            <p className="m-0 p-0">{t('documentation')}</p>
                            <p className="m-0 p-0 header-description">{t('documentationDescription')}</p>
                          </div>
                        </a>
                      </li>
                      <li>
                        <Link style={{textTransform: 'none'}} onClick={() => collapseNav()} className="dropdown-item nav-link d-flex align-items-center py-3 px-4" aria-current="page" to="/support">
                          <div>
                            <img className="me-4" style={{width: '40px'}} src='/icons/Work_hard.svg'/>
                          </div>
                          <div>
                            <p className="m-0 p-0">{t('support')}</p>
                            <p className="m-0 p-0 header-description">{t('supportDescription')}</p>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <a style={{textTransform: 'none'}} onClick={() => collapseNav()} className="dropdown-item nav-link d-flex align-items-center py-3 px-4" aria-current="page" href="https://status.synergyos.com">
                          <div>
                            <img className="me-4" style={{width: '40px'}} src='/icons/Security.svg'/>
                          </div>
                          <div>
                            <p className="m-0 p-0">{t('status')}</p>
                            <p className="m-0 p-0 header-description">{t('statusDescription')}</p>
                          </div>
                        </a>
                      </li>             
                    </ul>
                </li>
                <li className="nav-item">
                  <Link onClick={() => collapseNav()} className="nav-link" aria-current="page" to="/downloads">{t('downloads')}</Link>
                </li>
                <li className="nav-item">
                  <span className="nav-link no-pointer" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-expanded="false" style={{whiteSpace:"nowrap"}}>
                    {t('company')}
                  </span>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown2">
                    <li>
                      <Link style={{textTransform: 'none'}} onClick={() => collapseNav()} className="dropdown-item nav-link d-flex align-items-center py-3 px-4" aria-current="page" to="/about">
                        <div>
                          <img className="me-4" style={{width: '40px'}} src='/icons/Diversity.svg'/>
                        </div>
                        <div>
                          <p className="m-0 p-0">{t('about')}</p>
                          <p className="m-0 p-0 header-description">{t('aboutDescription')}</p>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link style={{textTransform: 'none'}} onClick={() => collapseNav()} className="dropdown-item nav-link d-flex align-items-center py-3 px-4" aria-current="page" to="/contact">
                        <div>
                          <img className="me-4" style={{width: '40px'}} src='/icons/contacts.svg'/>
                        </div>
                        <div>
                          <p className="m-0 p-0">{t('contact')}</p>
                          <p className="m-0 p-0 header-description">{t('contactUs')}</p>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <a style={{textTransform: 'none'}} onClick={() => collapseNav()} className="dropdown-item nav-link d-flex align-items-center py-3 px-4" aria-current="page" href="https://synergy-inc.breezy.hr/">
                        <div>
                          <img className="me-4" style={{width: '40px'}} src='/icons/Ownership.svg'/>
                        </div>
                        <div>
                          <p className="m-0 p-0">{t('careers')}</p>
                          <p className="m-0 p-0 header-description">{t('careersDescription')}</p>
                        </div>
                      </a>
                    </li>          
                  </ul>
                </li>
                {/*<li className="nav-item">
                  <Link className="nav-link" aria-current="page" to="https://trust.synergy.page">{t('trust')}</Link>
                </li>*/}   
                <li className="nav-item">
                  <span className="nav-link no-pointer d-flex align-items-center" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-expanded="false" style={{whiteSpace:"nowrap"}}>
                  <Icon className="me-2" iconName="Globe"/>{languages[i18next.language]}
                  </span>
                  <ul className="dropdown-menu" aria-labelledby="navbarDropdown2">
                    <li>
                        <div onClick={()=>{setLanguage('en')}} className="dropdown-item nav-link d-flex align-items-center px-4" style={{cursor: 'pointer'}}>
                          <p className="m-0 p-0">English</p>
                        </div>
                    </li>
                    <li>
                        <div onClick={()=>{setLanguage('es')}} className="dropdown-item nav-link d-flex align-items-center px-4"  style={{cursor: 'pointer'}}>
                          <p className="m-0 p-0">Español</p>
                        </div>
                    </li>          
                  </ul>
                </li>
                <li>
                <Link onClick={() => collapseNav()} className="nav-link" aria-current="page" to="/demo"><button type="button" className="main-button-header" >{t('demo')}</button></Link>
                </li>
                <li>
                <a className="nav-link" aria-current="page" href="https://synergyos.com/login"><button type="button" className="main-button-header-full" >{t('login')}</button></a>
                </li>
              </ul>  


            </div>
          </div>
      </nav>
    </div>
  )
}

export default Header
