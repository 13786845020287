/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import 'bootstrap/dist/js/bootstrap.min.js';
// import "bootstrap/dist/css/bootstrap.css"


import { initializeIcons } from '@fluentui/react'
import { loadTheme, createTheme } from '@fluentui/react';

initializeIcons(undefined, { disableWarnings: true });
loadTheme(createTheme({
  defaultFontStyle: { fontFamily: 'Inter'}
}));