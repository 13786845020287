import React from "react"
import { Link } from "gatsby"
import { useTranslation } from "react-i18next"

const Footer: React.FunctionComponent = () => {
  const { t } = useTranslation("app")

  return (

    <footer>
        <div className="container-fluid bg-darkBlue py-5">
            <div className="footer-content container-fluid maxWidth">
                <div className="pb-5">
                  <img src="/logo/synergy_long_white.png" height="24px" alt="Synergy Inc. logo" />
                </div>             
                <p className="m-0 pb-5" style={{textTransform:"none", fontSize:"17px", fontWeight:500}}>
                  <span>{t('emailAtUs')}</span>
                  <a className="mx-2" href="mailto:hello@synergyos.com" style={{color:"white"}}>hello@synergyos.com</a>
                </p>
                <ul className="m-0 p-0 font-weight-bold white-text">
                  <li className="me-3">
                    <Link to="/terms/privacy-policy">{t('privacyPolicy')}</Link>
                  </li>
                  <li className="me-3">
                    <Link to="/terms/terms-and-conditions">{t('termAndConditions')}</Link>
                  </li>
                  <li className="me-3">
                    <Link to="/terms/business-terms-and-conditions">{t('termAndConditionsBusiness')}</Link>
                  </li>
                </ul>
            </div>
        </div>
    </footer>
  )
}

export default Footer
