/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { withTrans } from '../i18n/withTrans'

import Header from "./header"
import Footer from "./footer"

import "../styles/styles.scss"
import CookieConsent, { getCookieConsentValue, resetCookieConsentValue } from "react-cookie-consent"

const Layout = ({ children, t, i18n }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  if (getCookieConsentValue('cookieConsent') !== 'true') resetCookieConsentValue('cookieConsent');

  return (
    <>
      <div>
        <div style={{minHeight: 'calc(100vh - 271px)'}}>
          <Header />
          <main>
            <CookieConsent
              location="bottom"
              buttonText="Yes, I'm happy"
              cookieName="cookieConsent"
              buttonClasses="accept-button"
              declineButtonClasses="decline-button"
              style={{ background: "#242431", zIndex: 99999999999 }}
              buttonStyle={{ color: "#ffffff", background:'#0064d2', marginRight: '15px' }}
              declineButtonStyle={{background: 'rgb(243, 242, 241)', color: '#000000'}}
              flipButtons
              buttonWrapperClasses="consent-buttons"
              declineButtonText="No, leave webpage"
              enableDeclineButton
              visible="byCookieValue"
              onDecline={() => {
                window.open('https://google.com', '_self');
              }}
            >
              <p style={{fontSize: '20px'}}>Are you happy to accept cookies?{" "}</p>
              <span>
                We use cookies and similar technologies to store and/or access information on a device and for content 
                measurement and insights. You can find out more in our <a style={{color:'white'}} href="/terms/privacy-policy">privacy policy</a>
              </span>
            </CookieConsent>
            {children}
          </main>
        </div>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withTrans(Layout)
