import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18next.use(LanguageDetector).init({
    fallbackLng: 'en',
    resources: {
        en: {
            app: require('../locales/en/app.json')
        },
        es: {
            app: require('../locales/es/app.json'),
        }
    },
    detection: {
        order: ["querystring", "localStorage"],
        lookupQuerystring: "lng",
        caches: ["localStorage"]
    },
    ns: ['translations'],
    defaultNS: 'translations',
    returnObjects: true,
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
        escapeValue: false, // not needed for react!!
    },
    react: {
        wait: true,
    },
});

i18next.languages = ['en', 'es'];

export default i18next;